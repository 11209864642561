import React, { useState } from 'react';
import { ChevronRight, ChevronLeft, Send, Edit } from 'lucide-react';

const questions = [
  { id: 'bigIdea', question: "What's your big idea in a nutshell? (1-2 sentences)", type: 'text' },
  { id: 'targetAudience', question: "Who's this for? Paint a picture of your ideal user. (2-3 sentences)", type: 'text' },
  { id: 'mainFeature', question: "What's the main thing your app or website needs to do? (1-2 sentences)", type: 'text' },
  { id: 'additionalFeatures', question: "List 3-5 other cool features you'd love to see. (Bullet points)", type: 'textarea' },
  { id: 'designPreferences', question: "How should it look and feel? Any brands or apps you admire? (2-3 sentences)", type: 'text' },
  { id: 'integrations', question: "Any must-have integrations with other tools or services? (List up to 3)", type: 'text' },
  { id: 'accessMethods', question: "How will users access your solution?", type: 'select', options: ['Web app', 'Mobile app (iOS)', 'Mobile app (Android)', 'Desktop software', 'Mix of platforms'] },
  { id: 'timeline', question: "What's your timeline looking like? (1 sentence)", type: 'text' },
  { id: 'budget', question: "Budget ballpark?", type: 'select', options: ['No Budget','$5,000 - $10,000', '$10,000 - $25,000', '$25,000 - $50,000', '$50,000 - $100,000', '$100,000+'] },
  { id: 'techPreferences', question: "Any specific tech preferences or limitations? (1-2 sentences)", type: 'text' },
  { id: 'monetization', question: "How do you envision making money with this? (1-2 sentences)", type: 'text' },
  { id: 'competitors', question: "Who are your main competitors, and how will you stand out? (2-3 sentences)", type: 'text' },
  { id: 'legalCompliance', question: "Any legal or compliance issues we should be aware of? (1-2 sentences)", type: 'text' },
  { id: 'scalingPlans', question: "How do you see this scaling in the future? (2-3 sentences)", type: 'text' },
  { id: 'security', question: "Any specific security concerns or requirements? (1-2 sentences)", type: 'text' },
  { id: 'brandGuidelines', question: "Do you have any existing brand guidelines or assets?", type: 'radio', options: ['Yes', 'No'] },
  { id: 'successMetrics', question: "How will you measure success? (List 3-5 key metrics)", type: 'textarea' },
  { id: 'thirdPartyAPIs', question: "Any third-party APIs or services you definitely want to use? (List if any)", type: 'text' },
  { id: 'customerSupport', question: "What's your plan for customer support and feedback? (1-2 sentences)", type: 'text' },
  { id: 'additionalInfo', question: "Anything else you think we should know? (2-3 sentences)", type: 'textarea' },
];

const contactFields = [
  { id: 'firstName', label: 'First Name', type: 'text' },
  { id: 'lastName', label: 'Last Name', type: 'text' },
  { id: 'email', label: 'Email', type: 'email' },
  { id: 'cell', label: 'Cell Phone', type: 'tel' },
  { id: 'linkedin', label: 'LinkedIn', type: 'url' },
  { id: 'website', label: 'Website', type: 'url' },
  { id: 'address', label: 'Address', type: 'text' },
  { id: 'location', label: 'Location', type: 'text' },
  { id: 'calendarLink', label: 'Calendar Link', type: 'url' },
  { id: 'assistantContact', label: 'Assistant Contact', type: 'text' },
];

const SRSInterviewApp = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [contactInfo, setContactInfo] = useState({});
  const [editingQuestion, setEditingQuestion] = useState(null);

  const handleInputChange = (id, value) => {
    setAnswers(prev => ({ ...prev, [id]: value }));
  };

  const handleContactInfoChange = (id, value) => {
    setContactInfo(prev => ({ ...prev, [id]: value }));
  };

  const handleNext = () => {
    if (currentStep < questions.length + 1) {
      setCurrentStep(currentStep + 1);
      setEditingQuestion(null);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setEditingQuestion(null);
    }
  };

  const handleUpdate = (id) => {
    setEditingQuestion(id);
    setCurrentStep(questions.findIndex(q => q.id === id));
  };

  const handleSubmit = async () => {
    const allData = { ...answers, contactInfo };
    try {
      const response = await fetch('https://hook.us1.make.com/mf8ac7bn3k6j8xsxi3c1reeui5edqwff', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(allData),
      });
      if (response.ok) {
        alert('Thank you! Your SRS interview has been submitted successfully.');
      } else {
        throw new Error('Failed to submit');
      }
    } catch (error) {
      alert('There was an error submitting your SRS interview. Please try again.');
    }
  };

  const renderQuestion = (question) => {
    switch (question.type) {
      case 'text':
      case 'email':
      case 'tel':
      case 'url':
        return (
          <input
            type={question.type}
            value={answers[question.id] || ''}
            onChange={(e) => handleInputChange(question.id, e.target.value)}
            className="w-full p-2 border rounded"
          />
        );
      case 'textarea':
        return (
          <textarea
            value={answers[question.id] || ''}
            onChange={(e) => handleInputChange(question.id, e.target.value)}
            className="w-full p-2 border rounded h-32"
          />
        );
      case 'select':
        return (
          <select
            value={answers[question.id] || ''}
            onChange={(e) => handleInputChange(question.id, e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select an option</option>
            {question.options.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        );
      case 'radio':
        return (
          <div className="flex gap-4">
            {question.options.map((option) => (
              <label key={option} className="flex items-center">
                <input
                  type="radio"
                  value={option}
                  checked={answers[question.id] === option}
                  onChange={(e) => handleInputChange(question.id, e.target.value)}
                  className="mr-2"
                />
                {option}
              </label>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  const renderContactInfo = () => (
    <div className="space-y-4">
      {contactFields.map((field) => (
        <div key={field.id}>
          <label className="block mb-1">{field.label}</label>
          <input
            type={field.type}
            value={contactInfo[field.id] || ''}
            onChange={(e) => handleContactInfoChange(field.id, e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
      ))}
    </div>
  );

  const renderCompletedQuestions = () => (
    <div className="mt-8 space-y-4">
      <h3 className="text-xl font-semibold">Completed Questions</h3>
      {questions.slice(0, currentStep).map((q) => (
        <div key={q.id} className="bg-gray-100 p-4 rounded">
          <h4 className="font-semibold">{q.question}</h4>
          <p className="mt-2">{answers[q.id] || 'Not answered'}</p>
          <button
            onClick={() => handleUpdate(q.id)}
            className="mt-2 flex items-center text-blue-500 hover:text-blue-700"
          >
            <Edit size={16} className="mr-1" />
            Update
          </button>
        </div>
      ))}
    </div>
  );

  const renderOverview = () => (
    <div className="space-y-4">
      <h2 className="text-xl font-bold">Overview of Your Responses</h2>
      {questions.map((q) => (
        <div key={q.id} className="bg-gray-100 p-4 rounded">
          <h3 className="font-semibold">{q.question}</h3>
          <p>{answers[q.id] || 'Not answered'}</p>
          <button
            onClick={() => handleUpdate(q.id)}
            className="mt-2 flex items-center text-blue-500 hover:text-blue-700"
          >
            <Edit size={16} className="mr-1" />
            Update
          </button>
        </div>
      ))}
      <h2 className="text-xl font-bold mt-8">Contact Information</h2>
      {contactFields.map((field) => (
        <div key={field.id} className="bg-gray-100 p-4 rounded">
          <h3 className="font-semibold">{field.label}</h3>
          <p>{contactInfo[field.id] || 'Not provided'}</p>
        </div>
      ))}
    </div>
  );

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-8">SRS Interview</h1>
      {currentStep < questions.length ? (
        <div>
          <h2 className="text-xl mb-4">{questions[currentStep].question}</h2>
          {renderQuestion(questions[currentStep])}
        </div>
      ) : currentStep === questions.length ? (
        renderContactInfo()
      ) : (
        renderOverview()
      )}
      <div className="flex justify-between mt-8">
        {currentStep > 0 && (
          <button onClick={handlePrevious} className="flex items-center px-4 py-2 bg-gray-200 rounded">
            <ChevronLeft size={20} />
            Previous
          </button>
        )}
        {currentStep < questions.length + 1 ? (
          <button onClick={handleNext} className="flex items-center px-4 py-2 bg-blue-500 text-white rounded ml-auto">
            Next
            <ChevronRight size={20} />
          </button>
        ) : (
          <button onClick={handleSubmit} className="flex items-center px-4 py-2 bg-green-500 text-white rounded ml-auto">
            Submit
            <Send size={20} className="ml-2" />
          </button>
        )}
      </div>
      {currentStep < questions.length && renderCompletedQuestions()}
    </div>
  );
};

export default SRSInterviewApp;